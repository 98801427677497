import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Vydrio Technologies LLC</h1>
        <h2>IT solutions as clear as glass</h2>
        <h3>About Vydrio</h3>
        <p>The focus of vydrio is all about transparency. Deriving from the Spanish word <i>vidrio</i>  or <i>'glass'</i>, our mission is to provide clarity to complex technical problems. </p>
        <h3>Services We Offer</h3>
        <h4>Frontend</h4>
        <ul>
          <li>iOS and Android Apps</li>
          <li>Hybrid platform Apps</li>
          <li>Websites and Progressive Web Apps</li>
          <li>Frontend Analytics and Optimizations</li>
        </ul>
        <h4>Backend and Infrastructure Products</h4>
        <ul>
          <li>Containerized Backends</li>
          <li>Cloud Computing Solutions </li>
          <li>CI/CD Pipelines</li>
          <li>Security and Authentication</li>
        </ul>

        <h4>Artificial Intelligence and Machine Learning</h4>
        <ul>
          <li>Web Scraping Solutions</li>
          <li>Model Optimizations</li>
          <li>Data Pipelines</li>
          <li>End-to-End Proof of Concepts</li>
        </ul>

        <h3>Contact Us</h3>
        <p>info@vydr.io</p>
      </header>
    </div>
  );
}

export default App;
